import React from "react";
import Addcontest from "Pages/Profile/Contest/Addcontest";


export default function Dashaddcontesttheme() {
  return (
    <>
       <Addcontest/>
    </>
  );
}
