import React, { useState } from "react";
import Fade from "react-reveal";
import Slider from "react-slick";

// images
import Nodatagif from "../../../assets/images/Nodat-found.gif";
import Articletable from "./Cmscomponents/articletable";
import Contactsupport from "./Cmscomponents/contactsupport";
import Faqs from "./Cmscomponents/faqs";
import Socialurls from "./Cmscomponents/socialurls";

const Cmstabletab = () => {
  const tabs = [
    { id: "tab1", name: "Terms and Conditions Aspiring Users" },
    { id: "tab2", name: "Terms and Conditions Established Users" },
    { id: "tab3", name: "Privacy Policy Aspiring Users" },
    { id: "tab4", name: "Privacy Policy Established User" },
    { id: "tab5", name: "About Us" },
    { id: "tab6", name: "Contact Support" },
    { id: "tab7", name: "FAQs" },
    { id: "tab8", name: "Social Url" },
  ];

  const [state, setState] = useState({ tab: tabs[0].id });

  //   const handleNext = () => {
  //     const currentIndex = tabs.findIndex((tab) => tab.id === state.tab);
  //     if (currentIndex < tabs.length - 1) {
  //       setState({ tab: tabs[currentIndex + 1].id });
  //     }
  //   };

  //   const handlePrev = () => {
  //     const currentIndex = tabs.findIndex((tab) => tab.id === state.tab);
  //     if (currentIndex > 0) {
  //       setState({ tab: tabs[currentIndex - 1].id });
  //     }
  //   };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    centerMode: false,
    focusOnSelect: true,
    beforeChange: (current, next) => {
      if (tabs[next]) {
        setState({ tab: tabs[next].id });
      }
    },
  };

  return (
    <section className="cmspage_tab">
      <div className="cmsslider">
        <div className="tab-frame cms_frame">
          <div className="clearfix">
            <Slider {...settings}>
              {tabs.map((tab, index) => (
                <React.Fragment key={tab.id}>
                  <input
                    type="radio"
                    name="tab"
                    id={tab.id}
                    checked={state.tab === tab.id}
                    onChange={() => setState({ tab: tab.id })}
                  />
                  <label htmlFor={tab.id}>{tab.name}</label>
                </React.Fragment>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="cmstabs_wrap text-start p-4 mt-5">
        {state.tab == "tab1" && (
          <div className="articletab">
            <Fade>
              <Articletable />

              {/* <div className="no_data text-center">
                  <img src={Nodatagif} alt="" className="img-fluid"/>
                  <p>No Data Found!</p>
              </div> */}
            </Fade>
          </div>
        )}

        {state.tab == "tab2" && (
          <div className="termsconditionEU">
            <Fade></Fade>
          </div>
        )}

        {state.tab == "tab3" && (
          <div className="privacypolicyeAS">
            <Fade></Fade>
          </div>
        )}

        {state.tab == "tab4" && (
          <div className="privacypolicyEU">
            <Fade></Fade>
          </div>
        )}

        {state.tab == "tab5" && (
          <div className="about_us">
            <Fade></Fade>
          </div>
        )}

        {state.tab == "tab6" && (
          <div className="contactsupport">
            <Fade>
              <Contactsupport />
            </Fade>
          </div>
        )}

        {state.tab == "tab7" && (
          <div className="faqs_main">
            <Fade>
                <Faqs/>
            </Fade>
          </div>
        )}

        {state.tab == "tab8" && (
          <div className="socail_main">
            <Fade>
               <Socialurls/>
            </Fade>
          </div>
        )}

        
      </div>
    </section>
  );
};

export default Cmstabletab;
