
import React from "react";
import Subadmin from "Pages/Profile/Subadmin";




export default function Dashsubadmintheme() {
  return (
    <>
       <Subadmin/>
    </>
  );
}
