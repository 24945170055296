import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Usertabbing from "./usertabbing";

import "rsuite/dist/rsuite.min.css";
import { DatePicker, Stack } from "rsuite";

// images
import Searchicon from "../../../../assets/svg/search_icon.svg";

const Pendingchatroom = () => {
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage Chatrooms Requests</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-center gap-3">
                    <FormGroup className="position-relative">
                      <Input
                        type="search"
                        name="email"
                        id="headersearch"
                        placeholder="Name"
                      />
                      <div className="search_icon">
                        <img src={Searchicon} alt="" className="img-fluid" />
                      </div>
                    </FormGroup>

                    {/* <FormGroup className="position-relative">
                    <Input
                      type="search"
                      name="email"
                      id="headersearch"
                      placeholder="Email"
                    />
                    <div className="search_icon">
                      <img src={Searchicon} alt="" className="img-fluid" />
                    </div>
                  </FormGroup> */}

                    <div className="calender_set">
                      <Stack
                        direction="column"
                        spacing={8}
                        alignItems="flex-start"
                      >
                        <DatePicker size="md" placeholder="Posted on" />
                      </Stack>
                    </div>

                  
                  </div>
                  <h5>Sort by :</h5>

                  <div className="dropdown_col">
                    <UncontrolledDropdown>
                      <DropdownToggle caret>A-Z</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>A-Z</DropdownItem>
                        <DropdownItem>Z-A</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>

              </div>

            
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <Usertabbing />
        </div>
      </section>
    </Fade>
  );
};

export default Pendingchatroom;
