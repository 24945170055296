import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { Button, Collapse } from "reactstrap";
import { useNavigate } from "react-router-dom";

// images
import Dashlogo from "../../assets/images/jamme_logo.svg";
import Logocollapse from "../../assets/images/logocollapse.png";

import Dashicon from "../../assets/svg/dash_icon.svg";
import Pendingeq from "../../assets/svg/pending_req.png";
import Usericon from "../../assets/svg/user_icon.svg";
import Skillicon from "../../assets/svg/skill_icon.png";
import Libararyicon from "../../assets/svg/libarary_icon.svg";
import Troffyicon from "../../assets/svg/contest_icon.svg";
import Eventicon from "../../assets/svg/event_icon.svg";
import Subadminicon from "../../assets/svg/sub_admin_icon.svg";
import Notiicon from "../../assets/svg/noti_icon.svg";
import Dataicon from "../../assets/svg/data_icon.svg";
import Customericon from "../../assets/svg/customer_icon.svg";
import Cmsicon from "../../assets/svg/cms_icon.svg";
import Chatroomicon from "../../assets/svg/chatroom_icon.svg";


const Profilesidebar = (props) => {
  const navigate = useNavigate();
  // const [openMenus, setOpenMenus] = useState({
  //   menu1: false,
  // });

  // const toggleMenu = (menuName) => {
  //   setOpenMenus((prevState) => ({
  //     ...prevState,
  //     [menuName]: !prevState[menuName],
  //   }));
  // };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [isOpentwo, setIsOpentwo] = useState(false);
  const toggle2 = () => setIsOpentwo(!isOpentwo);

  const [isOpenthree, setIsOpenthree] = useState(false);
  const toggle3 = () => setIsOpenthree(!isOpenthree);

  return (
    <section className="profile_side">
        

      <div className="profile_side_inner position-relative">
        {props.sidebarHidden ? (
          <div className="dashlogocollapse px-0 pt-3 pb-0">
            <img src={Logocollapse} alt="" className="img-fluid" />
          </div>
        ) : (
          <div className="dashlogo">
            <img src={Dashlogo} alt="" className="img-fluid" />
          </div>
        )}

        <ul className="dash_nav position-relative" id="side-menu">
          <li>
            <NavLink to="/" className="btn btn-primary">
              <span className="icon-right">
                <img src={Dashicon} alt="" className="img-fluid"  title="Dashboard"/>
              </span>
              Dashboard
              {/* <span className="arrow-right">
                {isOpentwo ? <>{arrowright}</> : <>{arrodown}</>}
              </span> */}
            </NavLink>
          </li>
          <li>
            <Link
              to="#"
              className="btn btn-primary"
              onClick={toggle}
              aria-expanded={isOpen}
              aria-controls="collapsepatinets"
            >
              <span className="icon-right">
                <img src={Pendingeq} alt="" className="img-fluid" title="Pending Requests"/>
              </span>
              Pending Requests
              {/* <span className="arrow-right">
                {isOpen ? <>{arrowright}</> : <>{arrodown}</>}
              </span> */}
            </Link>
            <Collapse isOpen={isOpen} id="collapsepatinets">
              <ul className="submenu">
                <li>
                  <NavLink to="/pending-users"  title="Users">
                    Users
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/change-role-request"
                    title="  Change Role Request"
                  >
                    Change Role Request
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/pending-pitches"  title="Pitches">
                    Pitches
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/pending-posts"  title="Posts">
                    Posts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contests-challenges"
                    title="Contests and challenges"
                  >
                    Contests and challenges
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/pending-events" title="Events">
                    Events
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/pending-request-chatroom" title="Chatrooms">
                    Chatrooms
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>

          <li>
            <NavLink to="/manage-users" className="btn btn-primary">
              <span className="icon-right">
                <img src={Usericon} alt="" className="img-fluid" title="Users"/>
              </span>
              Users
            </NavLink>
          </li>
{/* 
          <li>
            <Link
              to="/category"
              className="btn btn-primary"
              onClick={toggle2}
              aria-expanded={isOpentwo}
              aria-controls="collapseDischargeSummary"
            >
              <span className="icon-right">
                <img src={Dashicon} alt="" className="img-fluid" title="Categories"/>
              </span>
              Categories
               <span className="arrow-right">
                {isOpentwo ? <>{arrowright}</> : <>{arrodown}</>}
              </span> 
            </Link>
            <Collapse isOpen={isOpentwo} id="collapseDischargeSummary">
              <ul className="submenu">
                <li>
                  <NavLink to="/pitch-category" title="Pitch"> 
                    Pitch
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/category" title="Resource Library">
                    Resource Library
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/contest-category"
                    title="Contests and challenges"
                  >
                    Contests and challenges
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/events-category/" title="Events">
                    Events
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li> */}
{/* 
          <li>
            <NavLink to="/skill-and-interest" className="btn btn-primary">
              <span className="icon-right">
                <img src={Skillicon} alt="" className="img-fluid" title=" Skill & Interests"/>
              </span>
              Skill & Interests
            </NavLink>
          </li>

          <li>
            <NavLink to="/library" className="btn btn-primary">
              <span className="icon-right">
                <img src={Libararyicon} alt="" className="img-fluid" title="Resource library"/>
              </span>
              Resource library
            </NavLink>
          </li> */}

          <li>
            <NavLink to="/contests" className="btn btn-primary">
              <span className="icon-right">
                <img src={Troffyicon} alt="" className="img-fluid" title="Contests & Challenges"/>
              </span>
              Contests & Challenges
            </NavLink>
          </li>

          <li>
            <NavLink to="/events" className="btn btn-primary">
              <span className="icon-right">
                <img src={Eventicon} alt="" className="img-fluid" title="Events"/>
              </span>
              Events
            </NavLink>
          </li>

          <li>
            <NavLink to="/pitches" className="btn btn-primary">
              <span className="icon-right">
                <img src={Eventicon} alt="" className="img-fluid" title="Events"/>
              </span>
              Pitch
            </NavLink>
          </li>


          <li>
            <NavLink to="/chatroom" className="btn btn-primary">
              <span className="icon-right">
                <img src={Chatroomicon} alt="" className="img-fluid" title="Chatroom"/>
              </span>
              Chatroom
            </NavLink>
          </li>


          <li>
            <NavLink to="/sub-admin" className="btn btn-primary">
              <span className="icon-right">
                <img src={Subadminicon} alt="" className="img-fluid" title="  Sub-Admin"/>
              </span>
              Sub-Admin
            </NavLink>
          </li>

          <li>
            <NavLink to="/notification" className="btn btn-primary">
              <span className="icon-right">
                <img src={Notiicon} alt="" className="img-fluid" title="Notification"/>
              </span>
              Notification
            </NavLink>
          </li>

          <li>
            <NavLink to="/data-analytics" className="btn btn-primary">
              <span className="icon-right">
                <img src={Dataicon} alt="" className="img-fluid" title="Data & Analytics"/>
              </span>
              Data & Analytics
            </NavLink>
          </li>

          <li>
            <NavLink to="/customer-support" className="btn btn-primary">
              <span className="icon-right">
                <img src={Customericon} alt="" className="img-fluid" title="Customer Support"/>
              </span>
              Customer Support
            </NavLink>
          </li>

          <li>
            <NavLink to="/report-user" className="btn btn-primary">
              <span className="icon-right">
                <img src={Customericon} alt="" className="img-fluid" title="Report User"/>
              </span>
              Report User
            </NavLink>
          </li>

          <li>
            <NavLink to="/cms" className="btn btn-primary">
              <span className="icon-right">
                <img src={Cmsicon} alt="" className="img-fluid" title="CMS Pages"/>
              </span>
              CMS Pages
            </NavLink>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Profilesidebar;

const patienticon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
  >
    <path
      d="M15.6216 9.99248L15.1176 16.1372C14.9887 17.7271 12.6215 17.5083 12.7504 15.9458L13.141 11.1605L11.5355 11.2503C11.93 12.059 12.1527 12.9652 12.1527 13.9262C12.1527 15.5356 11.5238 17.0005 10.5003 18.0865L8.96511 16.5513C11.2269 14.0629 9.47684 10.0198 6.0744 10.0198C5.06265 10.0198 4.14075 10.4066 3.44932 11.0355L1.91412 9.50028C2.77352 8.69166 3.87121 8.12915 5.0939 7.92992L8.03539 4.58217L6.37128 3.61339L4.3556 5.41032C3.18369 6.45722 1.5977 4.68764 2.77352 3.63683L5.42985 1.26566C5.81267 0.921902 6.37128 0.867213 6.81661 1.12503C6.81661 1.12503 12.2582 4.28528 12.266 4.2931C12.8988 4.68764 13.0746 5.69939 12.5043 6.34784L10.223 8.94558L14.3676 8.7151C15.0903 8.67213 15.6802 9.27762 15.6216 9.99248ZM13.0863 3.97668C14.184 3.97668 15.0747 3.08603 15.0747 1.98834C15.0747 0.890651 14.184 0 13.0863 0C11.9886 0 11.098 0.890651 11.098 1.98834C11.098 3.08603 11.9886 3.97668 13.0863 3.97668ZM6.07831 17.8326C2.92977 17.8326 1.10159 14.2934 2.84774 11.7347L1.29691 10.1839C0.484389 11.2113 0 12.5121 0 13.9223C0 19.0279 5.88689 21.7897 9.8206 18.7076L8.26978 17.1568C7.64476 17.5826 6.89083 17.8326 6.07831 17.8326Z"
      fill="#4F4E4E"
    />
  </svg>
);

const arrodown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path fill="#4F4E4E" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z" />
  </svg>
);

const arrowright = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path fill="#4F4E4E" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z" />
  </svg>
);
