import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import "rsuite/dist/rsuite.min.css";
import { DatePicker, Stack } from "rsuite";

// images
import Searchicon from "../../../../assets/svg/search_icon.svg";
import dataHandler from "hooks/dataHandler";
import { PITCHES_LIST } from "services/ApiCalls";
import Pendingtable from "./pendingtable";
import SearchFilter from "Component/common/SearchFilter";
import SortByFilter from "Component/common/SortByFilter";
import DatePickerField from "Component/common/DatePickerField";
import moment from "moment";

const Pitches = () => {
  const {
    body,
    deleteHandler,
    deleteModel,
    setDeleteModel,
    paginationHandler,
    statusChangeHandler,
    total,
    loader,
    setBody,
    data,
    refetch,
  } = dataHandler({
    api: PITCHES_LIST,
    dataToSet: (data) => data?.data,
    extraBody: {
      accountType: "",
      status: 0,
      startDate: "",
      endDate: "",
    },
    dependencies: ["status", "order", "accountType", "startDate", "endDate"],
  });
  
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage Pitch Requests</h3>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-end gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-end gap-3">
                    <SearchFilter body={body} setBody={setBody} />

                    {/* <FormGroup className="position-relative">
                    <Input
                      type="search"
                      name="email"
                      id="headersearch"
                      placeholder="Email"
                    />
                    <div className="search_icon">
                      <img src={Searchicon} alt="" className="img-fluid" />
                    </div>
                  </FormGroup> */}

                    <DatePickerField
                      label="Start Date"
                      placeholder="Start Date"
                      
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          startDate: date && moment(date).format('YYYY/MM/DD'),
                        }))
                      }
                    />
                    <DatePickerField
                      label="End Date"
                      placeholder="End Date"
               
                      onChange={(date) =>
                        setBody((p) => ({
                          ...p,
                          endDate: date && moment(date).format('YYYY/MM/DD'),
                        }))
                      }
                    />

                    {/* <UserTypeFilter body={body} setBody={setBody} /> */}
                  </div>
                  <h5>Sort by :</h5>

                  <SortByFilter setBody={setBody} orderByKey="fname" />
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div className="user_tabbingset mt-5">
          <section className="tabbing_common">
            <div className="tab-frame user_frame">
              <div className="pending_user_content mt-4">
                <Pendingtable
                  data={data}
                  total={total}
                  body={body}
                  setBody={setBody}
                  paginationHandler={paginationHandler}
                  loader={loader}
                  statusChangeHandler={statusChangeHandler}
                />
              </div>
            </div>
          </section>
        </div>
      </section>
    </Fade>
  );
};

export default Pitches;
