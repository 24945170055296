import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Profilesidebar from "./Profilesidebar";
import Header from "Component/Header/Headerlogin";

const Dashboardwrap = () => {
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const toggleSidebar = () => {
    setSidebarHidden(!sidebarHidden);
  };
  return (
    <>
      <section
        className={`profile_main ${sidebarHidden ? "sidebar-hidden" : ""}`}
      >
        <div className="overlay_gradient"></div>
        <div className="profile_sidebar">
          <Profilesidebar
            toggleSidebar={toggleSidebar}
            sidebarHidden={sidebarHidden}
          />
        </div>

        <div className="profile_right_content position-relative">
          <div className="toggle_heade_wrap d-flex justify-content-between">
            <div className="d-flex gap-10">
              <Button onClick={toggleSidebar} className="mobile_toggle_btn">
                {/* {!sidebarHidden ?
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="#01336c" strokeLinecap="round" strokeWidth="2" d="M5 6h14M5 12h14M5 18h14" /></svg>
            </>
            :
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#01336c" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" /></svg>
            </>
          } */}

                <div className="togglebar">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Button>
              <div className="d-flex gap-10">
                <h4
                  className="mb-0 d-flex align-items-center"
                  style={{
                    color: "white",
                    fontSize: 18,
                  }}
                >
                  Hi admin
                  <img
                    src="/images/wave.png"
                    alt=""
                    className="img-fluid wave ms-3"
                  />
                </h4>
                <h4
                  className="mb-0 d-flex align-items-center"
                  style={{
                    color: "white",
                    fontSize: 18,
                  }}
                >
                  Welcome Back
                </h4>
              </div>
            </div>
            <Header />
          </div>

          <Outlet />
        </div>
      </section>
    </>
  );
};

export default Dashboardwrap;
