
import React from "react";
import Dataanalytics from "Pages/Profile/Dataanalytics";




export default function Dashdataanalyticstheme() {
  return (
    <>
       <Dataanalytics/>
    </>
  );
}
