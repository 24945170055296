import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

export default function Addacategorymod(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="Addacategory_mod"
    >
      <Modal.Body>
        <div className="Depositmod_inner_mod">
          <div className="d-flex justify-content-end align-item-center">
            <a href="#" className="close-modal" onClick={props.onhide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 23 23"
                fill="none"
              >
                <path
                  d="M23 3.46049L19.557 0L11.5 8.03951L3.46049 0L0 3.46049L8.03951 11.5L0 19.557L3.46049 23L11.5 14.9605L19.557 23L23 19.557L14.9605 11.5L23 3.46049Z"
                  fill="#6F6F6F"
                />
              </svg>
            </a>
          </div>

          <div className="addcategoriey text-start">
            <h5>Add Category</h5>
            <Form className="mt-5">
              <div className="modal_input">
                <FormGroup>
                  <Label for="exampleEmail">Category Name</Label>
                  <Input
                    type="text"
                    name="email"
                    id="exampleEmail"
                    placeholder="Name"
                  />
                </FormGroup>
              </div>
            </Form>
          </div>

          <div className="modal_button_center filter_head_button d-flex justify-content-end align-item-center gap-3 my-4">
            <Button className="import_btn" onClick={props.onhide}>
              Cancel
            </Button>
            <Button className="add_new_cust" onClick={props.onhide}>
              Save
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
