import React from "react";
import { FormGroup, Input } from "reactstrap";
import Searchicon from "../../assets/svg/search_icon.svg";

const SearchFilter = ({ body, setBody }) => {
  return (
    <FormGroup className="position-relative">
      <Input
        type="search"
        name="email"
        id="headersearch"
        placeholder="Search"
        value={body?.search}
        onChange={(e) =>
          setBody((p) => ({
            ...p,
            search: e.target.value,
          })) 
        }
      />
      <div className="search_icon">
        <img src={Searchicon} alt="" className="img-fluid" />
      </div>
    </FormGroup>
  );
};

export default SearchFilter;
