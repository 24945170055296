import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Aspiringtable from "./aspiringtable";
import Establishedtable from "./establishedtable";


const Faqs = () => {
  return (
    <section className="faqtabbing">
      <Tabs
        defaultActiveKey="AspiringUsers"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="AspiringUsers" title="Aspiring Users">
          <Aspiringtable />
        </Tab>
        <Tab eventKey="EstablishedUsers" title="Established Users">
          <Establishedtable />
        </Tab>
      </Tabs>
    </section>
  );
};

export default Faqs;
