import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Aspiringtable from "./Notifytables/aspiringtable";

import "rsuite/dist/rsuite.min.css";
import { DatePicker, Stack } from "rsuite";

// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import Plussvg from "../../../assets/svg/plussvg.svg";
import Nodatagif from "../../../assets/images/Nodat-found.gif";


const Notification = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ tab: "aspiringuser" });
  return (
    <Fade>
      <section className="Notification_page subadmin_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Notification</h3>
            </div>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4 justify-content-between">
            
              <div className="tab-frame notificationtab_frame">
                <div className="clearfix">
                  <input
                    type="radio"
                    name="tab"
                    id="aspiringuser"
                    checked={state.tab == "aspiringuser"}
                    onClick={() => setState({ tab: "aspiringuser" })}
                  />
                  <label for="aspiringuser">Aspiring Users</label>

                  <input
                    type="radio"
                    name="tab"
                    id="established"
                    checked={state.tab == "established"}
                    onClick={() => setState({ tab: "established" })}
                  />
                  <label for="established">Established Users</label>

                  <input
                    type="radio"
                    name="tab"
                    id="subadmin"
                    checked={state.tab == "subadmin"}
                    onClick={() => setState({ tab: "subadmin" })}
                  />
                  <label for="subadmin">Subadmin</label>
                </div>
              </div>

              <div className="export_btn d-flex align-items-center gap-3">
                <Button
                  className="plussvg"
                  onClick={() => navigate("/add-notification")}
                >
                  <img src={Plussvg} alt="" className="img-fluid" />
                </Button>
                <Button className="export_csv">Export CSV format</Button>
              </div>
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset mt-5">
          {state.tab == "aspiringuser" && (
            <div className="common_notification">
              <Aspiringtable />
            </div>
          )}

          {state.tab == "established" && (
            <div className="common_notification">
                <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
            </div>
          )}

          {state.tab == "subadmin" && (
            <div className="common_notification">
                <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
            </div>
          )}
        </div>
      </section>
    </Fade>
  );
};

export default Notification;
