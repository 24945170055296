import React from "react";
import Forgotpass from "Pages/Forgotpass";

export default function Forgotpasstheme() {
  return (
    <>
     <Forgotpass/>
    
    </>
  );
}
