import React, { useState } from "react";
import Fade from "react-reveal";
import Slider from "react-slick";


// images
import Nodatagif from "../../../assets/images/Nodat-found.gif"
import Articletable from "./categoriestables/articletable";

const Categorytab = () => {
  const tabs = [
    { id: "tab1", name: "Articles" },
    { id: "tab2", name: "Tutorials" },
    { id: "tab3", name: "Guides" },
    { id: "tab4", name: "Books" },
    { id: "tab5", name: "Podcasts" },
  ];

  const [state, setState] = useState({ tab: tabs[0].id });

  //   const handleNext = () => {
  //     const currentIndex = tabs.findIndex((tab) => tab.id === state.tab);
  //     if (currentIndex < tabs.length - 1) {
  //       setState({ tab: tabs[currentIndex + 1].id });
  //     }
  //   };

  //   const handlePrev = () => {
  //     const currentIndex = tabs.findIndex((tab) => tab.id === state.tab);
  //     if (currentIndex > 0) {
  //       setState({ tab: tabs[currentIndex - 1].id });
  //     }
  //   };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    focusOnSelect: true,
    beforeChange: (current, next) => setState({ tab: tabs[next].id }),
  };

  return (
    <section className="category_tab">

      <div className="tab_wrap">
      <h5 className="heading-h5">Categories</h5>

      <div className="cateslider">
        <div className="tab-frame category_frame">
          <div className="clearfix">
            <Slider {...settings}>
              {tabs.map((tab, index) => (
                <React.Fragment key={tab.id}>
                  <input
                    type="radio"
                    name="tab"
                    id={tab.id}
                    checked={state.tab === tab.id}
                    onChange={() => setState({ tab: tab.id })}
                  />
                  <label htmlFor={tab.id}>{tab.name}</label>
                </React.Fragment>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      </div>

      <div className="tabs_wrap text-start p-4">
        {state.tab === "tab1" && (
          <div className="articletab mt-4">
            <Fade>
            <h5 className="heading-h5">Sub-Categories</h5>

            <Articletable/>
            
            {/* <div className="no_data text-center">
                  <img src={Nodatagif} alt="" className="img-fluid"/>
                  <p>No Data Found!</p>
              </div> */}

            </Fade>
          </div>
        )}

        {state.tab === "tab2" && (
          <div className="tutorilatab mt-4">
            <Fade>
            <h5 className="heading-h5">Sub-Categories</h5>
            
            <div className="no_data text-center">
                  <img src={Nodatagif} alt="" className="img-fluid"/>
                  <p>No Data Found!</p>
              </div>

            </Fade>
          </div>
        )}

        {state.tab === "tab3" && (
          <div className="Guidestab mt-4">
            <Fade>
            <h5 className="heading-h5">Sub-Categories</h5>
            
            <div className="no_data text-center">
                  <img src={Nodatagif} alt="" className="img-fluid"/>
                  <p>No Data Found!</p>
              </div>

            </Fade>
          </div>
        )}

        {state.tab === "tab4" && (
          <div className="bookstab mt-4">
            <Fade>
            <h5 className="heading-h5">Sub-Categories</h5>
            
            <div className="no_data text-center">
                  <img src={Nodatagif} alt="" className="img-fluid"/>
                  <p>No Data Found!</p>
              </div>

            </Fade>
          </div>
        )}

        {state.tab === "tab5" && (
          <div className="Podcasts mt-4">
            <Fade>
            <h5 className="heading-h5">Sub-Categories</h5>
            
            <div className="no_data text-center">
                  <img src={Nodatagif} alt="" className="img-fluid"/>
                  <p>No Data Found!</p>
              </div>

            </Fade>
          </div>
        )}
      </div>
    </section>
  );
};

export default Categorytab;
