import React from "react";
import Addevents from "Pages/Profile/Events/Addevents";


export default function Dashaddeventtheme() {
  return (
    <>
       <Addevents/>
    </>
  );
}
