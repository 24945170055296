import React from "react";
import Resourceliberary from "Pages/Profile/Resourceliberary";


export default function Dashresourcelibtheme() {
  return (
    <>
       <Resourceliberary/>
    </>
  );
}
