import { MANAGE_PITCH } from "./ApiCalls";

export default {
  API_URL: "https://api.jamme3na.org/v1/",
  // ADMIN
  LOGIN_ADMIN: "/admin/login",
  FORGET_PASSWORD_ADMIN: "/admin/forgotPassword",
  VERIFY_OTP: "/admin/verifyOTP",
  RESET_PASSWORD: "/admin/resetPassword",
  // Users

  GET_USER_LIST: "/admin/listUsers",
  MANAGE_USER: "/admin/manageUsers",

  // events
  EVENT_LIST: "/admin/events/listEvents",
  MANAGE_EVENTS: "/admin/events/manageEvents",
  EVENT_DETAILS: "/admin/events/eventsDetails/",
  // Pitches
  GET_PITCH_LIST: "/admin/pitchs/listPitchs",
  MANAGE_PITCH: "/admin/pitchs/managePitchs",
  // events
  POST_LIST: "/admin/posts/listPosts",
  MANAGE_POSTS: "/admin/posts/managePosts",
  POST_DETAILS: "/admin/posts/postsDetails/",
};
