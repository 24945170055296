import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Contesttable from "./Contesttables/contesttable";



import "rsuite/dist/rsuite.min.css";
import { DatePicker, Stack } from "rsuite";

// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import Plussvg from "../../../assets/svg/plussvg.svg";

const Contest = () => {
  const navigate = useNavigate();
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Contests & Challenges</h3>

              <div className="toggle_side d-flex align-items-center gap-3">
                <h6>Enable from the App Side</h6>
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-center gap-3">
                    <FormGroup className="position-relative">
                      <Input
                        type="search"
                        name="email"
                        id="headersearch"
                        placeholder="Name"
                      />
                      <div className="search_icon">
                        <img src={Searchicon} alt="" className="img-fluid" />
                      </div>
                    </FormGroup>

                    <div className="calender_set">
                      <Stack
                        direction="column"
                        spacing={8}
                        alignItems="flex-start"
                      >
                        <DatePicker size="md" placeholder="Posted on" />
                      </Stack>
                    </div>

                    <div className="dropdown_col">
                      <UncontrolledDropdown>
                        <DropdownToggle caret>Category</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Aspiring User</DropdownItem>
                          <DropdownItem>Established User</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                  </div>
                  <h5>Sort by :</h5>

                  <div className="dropdown_col">
                    <UncontrolledDropdown>
                      <DropdownToggle caret>A-Z</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>A-Z</DropdownItem>
                        <DropdownItem>Z-A</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>

              <div className="export_btn d-flex align-items-center gap-3">
                <Button className="plussvg" onClick={()=> navigate("/add-contest")}>
                  <img src={Plussvg} alt="" className="img-fluid" />
                </Button>
                <Button className="export_csv">Export CSV</Button>
              </div>
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset mt-5">
           <Contesttable/>
        </div>
      </section>
    </Fade>
  );
};

export default Contest;
