
import React from "react";
import Reportuser from "Pages/Profile/Reportuser";




export default function Dashreportusertheme() {
  return (
    <>
       <Reportuser/>
    </>
  );
}
