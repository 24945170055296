import React from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Pagenotfound = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((s) => s?.login?.token);

  return (
    <section className="errorpage">
      <Container>
        <div className="erro404page">
          <h1 className="text-white">Page not found</h1>
          <Button
            className="big-add-token"
            onClick={() => navigate(isAuthenticated ? "/" : "/")}
          >
            <p>{isAuthenticated ? "Back to Home" : "Back to login"} </p>
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default Pagenotfound;
