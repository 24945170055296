import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Cmstabletab from "./cmstabletab";



// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import Plussvg from "../../../assets/svg/plussvg.svg";

const Cmspage = () => {

  return (
    <Fade>
    
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage CMS</h3>

            <div className="category_tab_set mt-5">
              <Cmstabletab />
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Cmspage;
