import React from "react";
import Loading from "./Loading";

const ButtonLoader = ({ size }) => {
  return (
    <div
      style={{
        width: size || "100%",
        height: size || "100%",
      }}
    >
      <Loading fullSize={true} />
    </div>
  );
};

export default ButtonLoader;
