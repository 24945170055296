import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

// images
import Logo from "../../assets/images/jamme_logo.svg";
import { z } from "zod";
import ForgetPassForm from "./ForgetPassForm";
import ResetPass from "./ResetPassForm";

const schema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email("Invalid email address"),
});

export const screens = {
  forgetPass: "forgetPass",
  resetPass: "resetPass",
};

const Forgotpass = () => {
  const [screen, setScreen] = useState(screens.forgetPass);
  const [email, setEmail] = useState("");
  return (
    <section className="wraaping_login authbg forgot_password">
      {screen === screens.forgetPass ? (
        <ForgetPassForm setScreen={setScreen} setEmail={setEmail} />
      ) : (
        <ResetPass setScreen={setScreen} email={email} />
      )}
    </section>
  );
};

export default Forgotpass;

// svg
const backicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
      fill="black"
    />
  </svg>
);
