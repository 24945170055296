import React from "react";
import {
  Table,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { Container } from "react-bootstrap";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { SlEye } from "react-icons/sl";


const pagetabledata = [
  {
     contenttype: "About us",
     status: "Active",
     datetiming: "10 Feb 2024",   
  },
  {
    contenttype: "FAQ",
    status: "Active",
    datetiming: "10 Feb 2024",   
 },
 {
  contenttype: "Privacy policy",
  status: "Active",
  datetiming: "10 Feb 2024",   
 },
 {
  contenttype: "Add Page",
  status: "Active",
  datetiming: "10 Feb 2024",   
 },
]



const Contentpagetable = () => {
  return (
    <section className="com_table roles_section">
      <Container>
        <Form>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox" />
                    </Label>
                  </FormGroup>
                </th>
                <th>Contect Type</th>
                <th>Status</th>
                <th>Date&Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pagetabledata.map((items, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" />
                        </Label>
                      </FormGroup>
                    </td>
                    <td><strong>{items.contenttype}</strong></td>
                    
                    <td>
                      {/*status-inactive */}
                      {/* status-yellow */}
                      <strong className={`status-${items.status === 'Active' ? 'active' : items.status === 'Inactive' ? 'inactive' : 'yellow'}`}>
                         {items.status}
                      </strong>
                    </td>
                    <td><strong>{items.datetiming}</strong></td>
                
                    <td>
                      <div className="action_btn">
                        <Button>
                          <SlEye />
                        </Button>
                        
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
            <p>1-2 of items</p>
            <Pagination aria-label="Page navigation example">
              <PaginationItem>
                <PaginationLink previous href="#" />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#" className="active">
                  1
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">2</PaginationLink>
              </PaginationItem>

              <PaginationItem>
                <PaginationLink next href="#" />
              </PaginationItem>
            </Pagination>
          </div>
        </Form>
      </Container>
    </section>
  );
};

export default Contentpagetable;
