import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from "reactstrap";
import ImageUploading from "react-images-uploading";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

// import
import Plussvg from "../../../../assets/svg/plussvg.svg";
import Editicon from "../../../../assets/svg/editicon.svg";
import Removeicon from "../../../../assets/svg/removeicon.svg";
import Downarrow from "../../../../assets/svg/downarrow.svg";

const Addsubadmin = () => {
  const [images, setImages] = useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const [value, setValue] = useState();

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Add Sub-Admin</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/sub-admin">Manage Sub-Admin </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Add Sub-Admin</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <div className="author_form">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="subname">Name</Label>
                      <Input
                        type="text"
                        name="subname"
                        id="subname"
                        placeholder="Name"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="phone_numberset">
                      <Label for="subname">Name</Label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        onChange={setValue}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="subemail">Email</Label>
                      <Input
                        type="text"
                        name="subemail"
                        id="subemail"
                        placeholder="Email"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}></Col>

                  <Col lg={6} md={6} sm={12}>
                    <div className="module_toggle">
                      <Table>
                        <thead>
                          <tr>
                            <th>Module</th>
                            <th>View</th>
                            <th>Add/Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Pending Requests</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Users</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Categories</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Skill & Interests</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Resource library</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Contests & Challenges</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Events</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Chatroom</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Notification</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Data & Analytics</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Customer Support</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Report User</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>CMS Pages</td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="toggle_side">
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col lg={6} md={6} sm={12}></Col>

                  <Col lg={6} md={6} sm={12} className="mt-4">
                    <Button className="export_csv w-100">Save</Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Addsubadmin;
