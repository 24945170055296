import React from "react";
import Contest from "Pages/Profile/Contest";


export default function Dashcontesttheme() {
  return (
    <>
       <Contest/>
    </>
  );
}
