import React from "react";
import loader from "../../assets/images/loader2.gif";

const Loading = ({ fullSize }) => {
  return (
    <img
      alt="Loader"
      style={{
        width: "3%",
        objectFit:"contain",
        ...(fullSize && { width: "100%", height: "100%" }),
      }}
      src={loader}
    />
  );
};

export default Loading;
