import React from "react";
import { Form } from "react-bootstrap";

const TableStatusOptionsAction = ({ value, onChange, statusOptionArr }) => {
  return (
    <Form.Select
      className="form-control rounded-pill w-auto h-auto fw-sbold"
      aria-label="Default select example"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {statusOptionArr.map((item) => {
        return (
          <option  value={item.value}>
            {item.label}
          </option>
        );
      })}
    </Form.Select>
  );
};

export default TableStatusOptionsAction;
