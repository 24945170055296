import React from "react";
import Dashboardpage from "Pages/Profile/Dashboard";

export default function Dashboardpagetheme() {
  return (
    <>
       <Dashboardpage/>
    </>
  );
}
