import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import ImageUploading from "react-images-uploading";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

// import
import Plussvg from "../../../../assets/svg/plussvg.svg";
import Editicon from "../../../../assets/svg/editicon.svg";
import Removeicon from "../../../../assets/svg/removeicon.svg";
import Downarrow from "../../../../assets/svg/downarrow.svg";


const Addresources = () => {
  const [images, setImages] = useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const [file, setFile] = useState(null);

  function handleChange(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      alert("Please select a valid PDF file.");
    }
  }

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Manage Resource library</h5>
            </div>

            <div className="breadcrumb_set mt-3">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/library">Manage Resource library</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Resource library Details</BreadcrumbItem>
              </Breadcrumb>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3">
              <div className="upload_imge_vedio">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <div className="images_wrap_show">
                        {imageList.length > 0 ? (
                          imageList.map((image, index) => (
                            <div
                              key={index}
                              className="image-item position-relative"
                            >
                              <img src={image["data_url"]} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <Button onClick={() => onImageUpdate(index)}>
                                  <img
                                    src={Editicon}
                                    alt="Edit"
                                    className="img-fluid"
                                  />
                                </Button>
                                <Button onClick={() => onImageRemove(index)}>
                                  <img
                                    src={Removeicon}
                                    alt="Remove"
                                    className="img-fluid"
                                  />
                                </Button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="upload_text text-white">
                            Upload Images/Video Here
                          </p>
                        )}
                      </div>

                      <Button
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="add_plusimg my-3"
                      >
                        <img src={Plussvg} alt="" className="img-fluid" />
                      </Button>
                    </div>
                  )}
                </ImageUploading>
              </div>

              <div className="pdf_upload position-relative">
                <label>
                  <input
                    type="file"
                    id="fileshow"
                    accept="application/pdf"
                    onChange={handleChange}
                  />
                </label>

                {file && (
                  <div className="file-preview">
                    <p>{file.name}</p> {/* Display the file name */}
                  </div>
                )}
              </div>

              <div className="author_form mt-5">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="authname">AuthorName</Label>
                      <Input
                        type="text"
                        name="authname"
                        id="authnameid"
                        placeholder="Author"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="title">Title</Label>
                      <Input
                        type="text"
                        name="title"
                        id="Titleid"
                        placeholder="Title"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative">
                      <Label for="Categoryset">Category</Label>
                      <Input type="select" name="select" id="Categoryset">
                        <option hidden disabled>
                          Category
                        </option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                      <div className="downImg">
                         <img src={Downarrow} alt="" />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative">
                      <Label for="SubCategory">Sub Category</Label>
                      <Input type="select" name="select" id="SubCategory">
                        <option hidden disabled>
                          Acting
                        </option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                      <div className="downImg">
                         <img src={Downarrow} alt="" />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="position-relative">
                      <Label for="Country">Country</Label>
                      <Input type="select" name="select" id="Country">
                        <option hidden disabled>
                          Country
                        </option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                      <div className="downImg">
                         <img src={Downarrow} alt="" />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Label for="Description">Add Description
                      </Label>
                      <Input type="textarea" name="text" id="Description" placeholder="Type here....."/>
                    </FormGroup>
                  </Col>


                  <Col lg={6} md={6} sm={12} className="mt-4">
                  <Button className="export_csv w-100">Save</Button>
                  </Col>
                  
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Addresources;
