import React from "react";
import Addresources from "Pages/Profile/Resourceliberary/Addresources";


export default function Dashaddresourcestheme() {
  return (
    <>
       <Addresources/>
    </>
  );
}
