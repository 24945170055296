import React from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from "reactstrap";
import { Row, Col } from "react-bootstrap";


const Socialurls = () => {

  return (
    <Fade>
      <section className="contacttabsupport ">
        <Form>
        
        <div className="addresources_bottom_form rounded-3 p-3">
              <div className="author_form">
                <Row>
                

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="subemail"
                        id="subemail"
                        placeholder="Facebook Url"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="subemail"
                        id="subemail"
                        placeholder="Instagram Url"
                      />
                    </FormGroup>
                  </Col>


                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="subemail"
                        id="subemail"
                        placeholder="Twitter Url"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="subemail"
                        id="subemail"
                        placeholder="Google Url"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={4} md={6} sm={12} className="mt-4 offset-lg-8">
                    <Button className="export_csv w-100">Save</Button>
                  </Col>
                </Row>
              </div>
            </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Socialurls;
