import React from "react";
import Addsubadmin from "Pages/Profile/Subadmin/Addsubadmin";


export default function Dashaddsubadmintheme() {
  return (
    <>
       <Addsubadmin/>
    </>
  );
}
