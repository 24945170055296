import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from "reactstrap";

import { Row, Col } from "react-bootstrap";

// import
import Plussvg from "../../../../assets/svg/plussvg.svg";
import Searchicon from "../../../../assets/svg/search_icon.svg";
import Filltertable from "../Notifytables/filltertable";

const Addnotification = () => {
  const [images, setImages] = useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const [state, setState] = useState({ tab: "aspiringuser" });

  return (
    <Fade>
      <section className="addresources comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h5 className="fillter_head">Manage Notification</h5>
            </div>

            <div className="tab-frame notificationtab_frame my-4">
              <div className="clearfix">
                <input
                  type="radio"
                  name="tab"
                  id="aspiringuser"
                  checked={state.tab == "aspiringuser"}
                  onClick={() => setState({ tab: "aspiringuser" })}
                />
                <label for="aspiringuser">Aspiring Users</label>

                <input
                  type="radio"
                  name="tab"
                  id="established"
                  checked={state.tab == "established"}
                  onClick={() => setState({ tab: "established" })}
                />
                <label for="established">Established Users</label>

                <input
                  type="radio"
                  name="tab"
                  id="subadmin"
                  checked={state.tab == "subadmin"}
                  onClick={() => setState({ tab: "subadmin" })}
                />
                <label for="subadmin">Subadmin</label>
              </div>
            </div>

            <div className="addresources_bottom_form rounded-3 p-3 mt-4">
              {state.tab == "aspiringuser" && (
                <div className="author_form">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="notifytitle">Notification Title</Label>
                        <Input
                          type="text"
                          name="notifytitle"
                          id="notifytitle"
                          placeholder="Enter Title"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="notifymessage">Notification Message</Label>
                        <Input
                          type="text"
                          name="notifymessage"
                          id="notifymessage"
                          placeholder="Enter Message"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={3} md={6} sm={12} className="offset-lg-9">
                      <Button className="export_csv w-100">Save</Button>
                    </Col>
                  </Row>
                </div>
              )}

              {state.tab == "established" && (
                <div className="author_form">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="notifytitle">Notification Title</Label>
                        <Input
                          type="text"
                          name="notifytitle"
                          id="notifytitle"
                          placeholder="Enter Title"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="notifymessage">Notification Message</Label>
                        <Input
                          type="text"
                          name="notifymessage"
                          id="notifymessage"
                          placeholder="Enter Message"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={3} md={6} sm={12} className="offset-lg-9">
                      <Button className="export_csv w-100">Save</Button>
                    </Col>
                  </Row>
                </div>
              )}

              {state.tab == "subadmin" && (
                <div className="author_form">
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="notifytitle">Notification Title</Label>
                        <Input
                          type="text"
                          name="notifytitle"
                          id="notifytitle"
                          placeholder="Enter Title"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <Label for="notifymessage">Notification Message</Label>
                        <Input
                          type="text"
                          name="notifymessage"
                          id="notifymessage"
                          placeholder="Enter Message"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={3} md={6} sm={12} className="offset-lg-9">
                      <Button className="export_csv w-100">Save</Button>
                    </Col>
                  </Row>
                </div>
              )}
            </div>

            <div className="fillter_bottom">
              <div className="fillter_area d-flex align-items-center justify-content-between my-4">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-center gap-3">
                    <FormGroup className="position-relative">
                      <Input
                        type="search"
                        name="email"
                        id="headersearch"
                        placeholder="Name"
                      />
                      <div className="search_icon">
                        <img src={Searchicon} alt="" className="img-fluid" />
                      </div>
                    </FormGroup>

                    <FormGroup className="position-relative">
                      <Input
                        type="search"
                        name="email"
                        id="headersearch"
                        placeholder="Email"
                      />
                      <div className="search_icon">
                        <img src={Searchicon} alt="" className="img-fluid" />
                      </div>
                    </FormGroup>
                  </div>
                </div>

                <div className="slelct_all_check">
                  <FormGroup>
                    <Label>
                      <Input type="checkbox" /> Select All
                    </Label>
                  </FormGroup>
                </div>
              </div>

              <Filltertable />
            </div>
          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Addnotification;
