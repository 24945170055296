import React from "react";
import Skillinterest from "Pages/Profile/Skillinterest";


export default function Dashskillinteresttheme() {
  return (
    <>
       <Skillinterest/>
    </>
  );
}
