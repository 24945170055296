import React from "react";
import Contentpage from "Pages/Profile/Contentpage";
import PendingPosts from "Pages/Profile/Pendinguser/PendingPost";

export default function DashPosttheme() {
  return (
    <>
        <PendingPosts/>
    </>
  );
}
