import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from "reactstrap";
import ImageUploading from "react-images-uploading";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

// import
import Plussvg from "../../../../assets/svg/plussvg.svg";
import Editicon from "../../../../assets/svg/editicon.svg";
import Removeicon from "../../../../assets/svg/removeicon.svg";
import Downarrow from "../../../../assets/svg/downarrow.svg";

const Contactsupport = () => {
  const [images, setImages] = useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const [value, setValue] = useState();

  return (
    <Fade>
      <section className="contacttabsupport ">
        <Form>
        
        <div className="addresources_bottom_form rounded-3 p-3">
              <div className="author_form">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <FormGroup className="phone_numberset">
                    
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        onChange={setValue}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                    
                      <Input
                        type="text"
                        name="subemail"
                        id="subemail"
                        placeholder="Email"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={4} md={6} sm={12} className="mt-4 offset-lg-8">
                    <Button className="export_csv w-100">Save</Button>
                  </Col>
                </Row>
              </div>
            </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Contactsupport;
