import React, { useState } from "react";
import Fade from "react-reveal";
import Welcomegreet from "Component/Welcomegreet";
import Newusertable from "./newusertable";
import Dashcards from "./dashcards";




const Dashboardpage = () => {
  return (
    <Fade>
      <section className="dashboard_first comon_dashpad">
            {/* <Welcomegreet/> */}
            <Dashcards/>
            <Newusertable/>
      </section>
    </Fade>
  );
};

export default Dashboardpage;
