import React, { useState } from "react";
import Pendingtable from "./Resourcestables/pendingtable";
import Declinetable from "./Resourcestables/declinetable";

// images
import Nodatagif from "../../../assets/images/Nodat-found.gif";

const Resourcetabbing = () => {
  const [state, setState] = useState({ tab: "tab1" });
  return (
    <section className="tabbing_common">
      <div className="tab-frame resources_frame">
        <div className="clearfix">
          <input
            type="radio"
            name="tab"
            id="tab1"
            checked={state.tab == "tab1"}
            onClick={() => setState({ tab: "tab1" })}
          />
          <label for="tab1">Articles</label>

          <input
            type="radio"
            name="tab"
            id="tab2"
            checked={state.tab == "tab2"}
            onClick={() => setState({ tab: "tab2" })}
          />
          <label for="tab2">Tutorials</label>

          <input
            type="radio"
            name="tab"
            id="tab3"
            checked={state.tab == "tab3"}
            onClick={() => setState({ tab: "tab3" })}
          />
          <label for="tab3">Guides</label>

          <input
            type="radio"
            name="tab"
            id="tab4"
            checked={state.tab == "tab4"}
            onClick={() => setState({ tab: "tab4" })}
          />
          <label for="tab4">Books</label>

          <input
            type="radio"
            name="tab"
            id="tab5"
            checked={state.tab == "tab5"}
            onClick={() => setState({ tab: "tab5" })}
          />
          <label for="tab5">Podcasts</label>
        </div>

        {state.tab == "tab1" && (
          <div className="pending_user_content mt-4">
            <Pendingtable />
          </div>
        )}

        {state.tab == "tab2" && (
          <div className="pending_user_content mt-4">
            {/* <Declinetable/> */}

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )}

        {state.tab == "tab3" && (
          <div className="pending_user_content mt-4">
            {/* <Declinetable/> */}

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )}

        {state.tab == "tab4" && (
          <div className="pending_user_content mt-4">
            {/* <Declinetable/> */}

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )}

        {state.tab == "tab5" && (
          <div className="pending_user_content mt-4">
            {/* <Declinetable/> */}

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Resourcetabbing;
