import Axios from "./Axios";
import API_URL from "./URLS";

export const LOGIN_ADMIN = (data) => Axios.post(API_URL.LOGIN_ADMIN, data);
export const FORGET_PASSWORD_ADMIN = (data) =>
  Axios.post(API_URL.FORGET_PASSWORD_ADMIN, data);
export const RESET_PASSWORD = (data) =>
  Axios.post(API_URL.RESET_PASSWORD, data);
export const VERIFY_OTP = (data) => Axios.post(API_URL.VERIFY_OTP, data);

// Users

export const USERS_LIST = (data) =>
  Axios.post(`${API_URL.GET_USER_LIST}`, data);

export const MANAGE_USER = (data) => Axios.post(`${API_URL.MANAGE_USER}`, data);

// EVENTS

export const EVENT_LIST = (data) => Axios.post(`${API_URL.EVENT_LIST}`, data);
export const MANAGE_EVENTS = (data) =>
  Axios.post(`${API_URL.MANAGE_EVENTS}`, data);
export const EVENT_DETAILS = (data) =>
  Axios.get(`${API_URL.EVENT_DETAILS}/${data}`);
//Pitch
export const PITCHES_LIST = (data) =>
  Axios.post(`${API_URL.GET_PITCH_LIST}`, data);
export const MANAGE_PITCH = (data) =>
  Axios.post(`${API_URL.MANAGE_PITCH}`, data);

// POSTS

export const POST_LIST = (data) => Axios.post(`${API_URL.POST_LIST}`, data);
export const MANAGE_POSTS = (data) =>
  Axios.post(`${API_URL.MANAGE_POSTS}`, data);
export const POST_DETAILS = (data) =>
  Axios.get(`${API_URL.POST_DETAILS}/${data}`);
