import React from "react";
import Cmspage from "Pages/Profile/Cmspage";


export default function Dashcmspagetheme() {
  return (
    <>
       <Cmspage/>
    </>
  );
}
