import React from "react";
import Events from "Pages/Profile/Events";


export default function Dasheventtheme() {
  return (
    <>
       <Events/>
    </>
  );
}
