import React from "react";
import Pitches from "Pages/Profile/Pendinguser/Pitches";


export default function Dashpitchestheme() {
  return (
    <>
       <Pitches/>
    </>
  );
}
