import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// importing all the themes

import Signintheme from "themes/signin-theme";
import Forgotpass from "themes/forgotpass-theme";

import Dashboardtheme from "themes/myprofile-theme";

import Chnagepasstheme from "themes/chnagepass-theme";
import Pagenotfound from "Pages/404page";
import Dashboardpagetheme from "themes/dashboard-theme";
import Dashpendingusertheme from "themes/dashpending-usertheme";
import Dashchnageroletheme from "themes/dashchnagerole-theme";
import Dashpitchestheme from "themes/dashpitches-theme";
import Dashcotestchalltheme from "themes/dashcotestchall-theme";
import Dashpendingeventstheme from "themes/dashpendingevents-theme";
import Dashmanageusertheme from "themes/dashmanageuser-theme";
import Dashmaincategorytheme from "themes/dashmaincategory-theme";
import Dashskillinteresttheme from "themes/dashskillinterest-theme";
import Dashresourcelibtheme from "themes/dashresourceliberary-theme";
import Dashaddresourcestheme from "themes/dashaddresources-theme";
import Dashcontesttheme from "themes/dashcontesttheme-theme";
import Dashaddcontesttheme from "themes/dashaddcontest-theme";
import Dasheventtheme from "themes/dashevent-theme";
import Dashaddeventtheme from "themes/dashaddevent-theme";
import Dashpedingchatroomtheme from "themes/dashpedingchatroom-theme";
import Dashchatroomtheme from "themes/dashchatroom-theme";
import Dashsubadmintheme from "themes/dashsubadmin-theme";
import Dashpitchtheme from "themes/dashpitch-theme";
import Dashaddsubadmintheme from "themes/dashaddsubadmin-theme";
import Dashnotificaitiontheme from "themes/dashnotificaition-theme";
import Dashaddnotifytheme from "themes/dashaddnotify-theme";
import Dashcustomersupporttheme from "themes/dashcustomersupport-theme";
import Dashreportusertheme from "themes/dashreportuser-theme";
import Dashcmspagetheme from "themes/dashcmspage-theme";
import Dashdataanalyticstheme from "themes/dashdataanalytics-theme";
import DashPosttheme from "themes/dashPost-theme";

class MyRouts extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            {!this.props?.isAuthenticated ? (
              <>
                <Route path="/" element={<Signintheme />} />
                <Route path="/forgot-password" element={<Forgotpass />} />
                <Route path="/change-password" element={<Chnagepasstheme />} />
                <Route path="*" element={<Pagenotfound />} />
              </>
            ) : (
              <>
                <Route element={<Dashboardtheme />}>
                  <Route path="/" element={<Dashboardpagetheme />} />
                  <Route
                    path="/pending-users"
                    element={<Dashpendingusertheme />}
                  />
                  <Route
                    path="/change-role-request"
                    element={<Dashchnageroletheme />}
                  />
                  <Route path="/pending-pitches" element={<Dashpitchestheme />} />
                  <Route
                    path="/contests-challenges"
                    element={<Dashcotestchalltheme />}
                  />
                  <Route
                    path="/pending-events"
                    element={<Dashpendingeventstheme />}
                  />
                  <Route
                    path="/pending-request-chatroom"
                    element={<Dashpedingchatroomtheme />}
                  />
                  <Route
                    path="/manage-users"
                    element={<Dashmanageusertheme />}
                  />

                  <Route path="/category" element={<Dashmaincategorytheme />} />

                  <Route
                    path="/skill-and-interest"
                    element={<Dashskillinteresttheme />}
                  />

                  <Route path="/library" element={<Dashresourcelibtheme />} />
                  <Route
                    path="/add-resources"
                    element={<Dashaddresourcestheme />}
                  />

                  <Route path="/contests" element={<Dashcontesttheme />} />
                  <Route path="/pending-posts" element={<DashPosttheme />} />

                  <Route
                    path="/add-contest"
                    element={<Dashaddcontesttheme />}
                  />

                  <Route path="/events" element={<Dasheventtheme />} />
                  <Route path="/pitches" element={<Dashpitchtheme/>} />

                  <Route path="/add-events" element={<Dashaddeventtheme />} />

                  <Route path="/chatroom" element={<Dashchatroomtheme />} />

                  <Route path="/sub-admin" element={<Dashsubadmintheme />} />
                  <Route path="/add-subadmin" element={<Dashaddsubadmintheme />} />



                  <Route path="/notification" element={<Dashnotificaitiontheme />} />
                  <Route path="/add-notification" element={<Dashaddnotifytheme />} />
               
                   
                  <Route path="/data-analytics" element={<Dashdataanalyticstheme />} />



                  <Route path="/customer-support" element={<Dashcustomersupporttheme />} />


                  <Route path="/report-user" element={<Dashreportusertheme />} />

                  <Route path="/cms" element={<Dashcmspagetheme />} />
                  


                </Route>

                <Route path="*" element={<Pagenotfound />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default MyRouts;
