import React from "react";
import Manageusers from "Pages/Profile/Manageusers";


export default function Dashmanageusertheme() {
  return (
    <>
       <Manageusers/>
    </>
  );
}
