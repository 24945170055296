import React, { useState } from "react";
import Pendingtable from "./pendingtable";
import Declinetable from "./declinetable";

// images

import { toast } from "react-toastify";

const Usertabbing = ({
  setBody,
  body,
  data,
  total,
  paginationHandler,
  loader,
}) => {
  return (
    <section className="tabbing_common">
      <div className="tab-frame user_frame">
        <div className="clearfix">
          <input
            type="radio"
            name="tab"
            id="pending"
            checked={body?.userStatus == "approved"}
            onClick={() => setBody((p) => ({ ...p, userStatus: "approved" }))}
          />
          <label for="pending">Approved</label>

          <input
            type="radio"
            name="tab"
            id="decline"
            checked={body?.userStatus == "decline"}
            onClick={() => setBody((p) => ({ ...p, userStatus: "decline" }))}
          />
          <label for="decline">Decline</label>
        </div>

        {body?.userStatus == "approved" && (
          <div className="pending_user_content mt-4">
            <Pendingtable
              data={data}
              total={total}
              body={body}
              setBody={setBody}
              paginationHandler={paginationHandler}
              loader={loader}
            />
          </div>
        )}

        {body?.userStatus == "decline" && (
          <div className="pending_user_content mt-4">
            <Pendingtable
              data={data}
              total={total}
              body={body}
              setBody={setBody}
              paginationHandler={paginationHandler}
              loader={loader}
            />

            {/* <Declinetable/> */}
          </div>
        )}
      </div>
    </section>
  );
};

export default Usertabbing;
