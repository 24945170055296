import React from "react";
import Changepass from "Pages/Changepass";


export default function Chnagepasstheme() {
  return (
    <>
     <Changepass/>
  
    </>
  );
}
