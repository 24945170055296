import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,Col
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import "rsuite/dist/rsuite.min.css";
import { DatePicker, Stack } from "rsuite";

// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import Plussvg from "../../../assets/svg/plussvg.svg";

const Reportuser = () => {
  const navigate = useNavigate();
  return (
    <Fade>
      <section className="subadmin_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Reported Reason</h3>
            </div>
          </div>
        </Form>

        <div className="reported_reason mt-4">
          <div className="author_form">
            <Row>
              <Col lg={6} md={6} sm={12}>
                <FormGroup className="position-relative">
                  <Label for="reportreason">Report Reason</Label>
                  <Input
                    type="text"
                    name="reportreason"
                    id="reportreason"
                    placeholder="Reason"
                  />
                  <Button className="add_reason">+</Button>
                </FormGroup>
              </Col>

              <Col lg={6} md={6} sm={12}>
              </Col>


              <Col lg={6} md={6} sm={12}>
                <Button className="export_csv w-100">Save</Button>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default Reportuser;
