import Pitches from "Pages/Profile/Pitches";
import React from "react";



export default function Dashpitchtheme() {
  return (
    <>
       <Pitches/>
    </>
  );
}
