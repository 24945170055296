import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import Plussvg from "../../../assets/svg/plussvg.svg";

const Dataanalytics = () => {
  const navigate = useNavigate();
  return (
    <Fade>
      <section className="subadmin_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Data & Analytics</h3>
            </div>
          </div>
        </Form>

        <div className="reported_reason mt-4">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="data_ananlytics_card">
                <div className="top_header d-flex align-items-center justify-content-between">
                  <h4>Total Contests</h4>

                  <div className="line"></div>

                  <UncontrolledDropdown>
                    <DropdownToggle caret>Daily</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>Daily</DropdownItem>
                      <DropdownItem>Montly</DropdownItem>
                      <DropdownItem>Yearly</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <div className="innner_dark_card rounded-3 p-3 mt-4"></div>
              </div>
            </Col>

            <Col lg={6} md={6} sm={12}>
              <div className="data_ananlytics_card">
                <div className="top_header d-flex align-items-center justify-content-between">
                  <h4>Total Chatrooms</h4>

                  <div className="line"></div>

                  <UncontrolledDropdown>
                    <DropdownToggle caret>Daily</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>Daily</DropdownItem>
                      <DropdownItem>Montly</DropdownItem>
                      <DropdownItem>Yearly</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <div className="innner_dark_card rounded-3 p-3 mt-4"></div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Fade>
  );
};

export default Dataanalytics;
