import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// images
import { zodResolver } from "@hookform/resolvers/zod";
import ButtonLoader from "Component/common/ButtonLoader";
import { useForm } from "react-hook-form";
import { FORGET_PASSWORD_ADMIN } from "services/ApiCalls";
import { catchAsync, checkResponse } from "utilities/utilities";
import { z } from "zod";
import Logo from "../../../assets/images/jamme_logo.svg";
import { screens } from "../index";

const schema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email("Invalid email address"),
});

const ForgetPassForm = ({ setEmail, setScreen }) => {
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const submitHandler = catchAsync(
    async (data) => {
      setLoader(true);

      const res = await FORGET_PASSWORD_ADMIN(data);

      const success = checkResponse({
        res,
        showSuccess: true,
        setLoader,
      });

      if (success) {
        setEmail(data.email);
        setScreen(screens.resetPass);
      }
    },
    { setLoader }
  );

  return (
    <Container>
      <div className="form_Side">
        <form
          id="loginform"
          className="spinn-content forgot_password position-relative"
          onSubmit={handleSubmit(submitHandler)}
        >
          <div className="login_heading text-center">
            <img src={Logo} alt="" className="img-fluid" />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="input-group">
                <label>Forgot Password ?</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your Email"
                  {...register("email")}
                />
              </div>
              {errors?.email && (
                <p className="text-danger m-0">{errors.email.message}</p>
              )}
            </div>

            <div className="col-lg-12 text-start mt-3 mb-lg-4">
              <Button type="submit" className="themebtn" disabled={loader}>
                {loader ? <ButtonLoader size={"100%"} /> : "Submit"}
              </Button>
            </div>

            <div className="forgor-password mt-3 text-center">
              <Link to="/" className="back_to_login">
                Back to login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default ForgetPassForm;

// svg
const backicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
      fill="black"
    />
  </svg>
);
