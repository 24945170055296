import React from "react";
import noImg from "../../../assets/images/no-img.png";

const TableProfile = ({ image }) => {
  return (
    <div className="table_img">
      <img src={image || noImg} alt="" className="img-fluid" />
    </div>
  );
};

export default TableProfile;
