import React from "react";
import Pendingchatroom from "Pages/Profile/Pendinguser/Pendingchatroom";


export default function Dashpedingchatroomtheme() {
  return (
    <>
       <Pendingchatroom/>
    </>
  );
}
