import React from "react";
import Chatroom from "Pages/Profile/Chatroom";


export default function Dashchatroomtheme() {
  return (
    <>
       <Chatroom/>
    </>
  );
}
