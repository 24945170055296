import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

// images
import Stafftheme from "../../assets/images/stafftheme.png";

const Forgotpass = () => {
  const navigate = useNavigate();
  return (
    <section className="wraaping_login authbg forgot_password">
      <Container>
        <div className="form_Side">
          <Link to="#" className="back_arrow" onClick={() => navigate(-1)}>
            {backicon}
          </Link>

          <form
            id="loginform"
            className="spinner-content forgot_password position-relative"
          > 
           <img src={Stafftheme} alt="" className="staff img-fluid"/>

            <div className="row">
              <div className="login_heading text-center my-lg-4 my-2">
                <h3>Enter Password</h3>
                <p>Lorem Ipsum is simply dummy text</p>
              </div>


              <div className="col-lg-12">
                <div className="input-group">
                  <label>Enter New Password</label>
                  <input type="password" name="password" placeholder="Enter Password" />
                </div>
              </div>


              <div className="col-lg-12">
                <div className="input-group">
                  <label>Confirm Password</label>
                  <input type="password" name="password" placeholder="Enter Password" />
                </div>
              </div>

              

              <div className="col-lg-12 text-start">
                <Button type="submit" className="themebtn" onClick={()=> navigate("/")}>
                  Update
                </Button>
              </div>

            
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default Forgotpass;

// svg
const backicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
      fill="black"
    />
  </svg>
);
