
import React from "react";
import Notification from "Pages/Profile/Notification";





export default function Dashnotificaitiontheme() {
  return (
    <>
       <Notification/>
    </>
  );
}
