import React, { useState } from "react";
import Fade from "react-reveal";

// images
import Nodatagif from "../../../assets/images/Nodat-found.gif";
import Skilltable from "./skillinteresttables/skilltable";

const Skillinteresttab = () => {
  const tabs = [
    { id: "tab1", name: "Skills" },
    { id: "tab2", name: "Interests" },
    { id: "tab3", name: "Expertise" },
  ];

  const [state, setState] = useState({ tab: tabs[0].id });

  return (
    <section className="category_tab skill_tab">
      <div className="tab_wrap">
        <div className="cateslider">
          <div className="tab-frame skilltab_frame">
            <div className="clearfix">
              {tabs.map((tab, index) => (
                <React.Fragment key={tab.id}>
                  <input
                    type="radio"
                    name="tab"
                    id={tab.id}
                    checked={state.tab === tab.id}
                    onChange={() => setState({ tab: tab.id })}
                  />
                  <label htmlFor={tab.id}>{tab.name}</label>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="tabs_wrap text-start p-4">
        {state.tab === "tab1" && (
          <div className="articletab mt-4">
            <Fade>
               <Skilltable/>
            </Fade>
          </div>
        )}

        {state.tab === "tab2" && (
          <div className="tutorilatab mt-4">
            <Fade>
              <div className="no_data text-center">
                <img src={Nodatagif} alt="" className="img-fluid" />
                <p>No Data Found!</p>
              </div>
            </Fade>
          </div>
        )}

        {state.tab === "tab3" && (
          <div className="Guidestab mt-4">
            <Fade>
              <div className="no_data text-center">
                <img src={Nodatagif} alt="" className="img-fluid" />
                <p>No Data Found!</p>
              </div>
            </Fade>
          </div>
        )}
      </div>
    </section>
  );
};

export default Skillinteresttab;
