import React, { useState } from "react";
import Pendingtable from "./pendingtable";
import Declinetable from "./declinetable";

// images
import Nodatagif from "../../../../assets/images/Nodat-found.gif";

const Usertabbing = () => {
  const [state, setState] = useState({ tab: "tab1" });
  return (
    <section className="tabbing_common">
      <div className="tab-frame user_frame">
        <div className="clearfix">
          <input
            type="radio"
            name="tab"
            id="tab1"
            checked={state.tab == "tab1"}
            onClick={() => setState({ tab: "tab1" })}
          />
          <label for="tab1">Pending</label>

          <input
            type="radio"
            name="tab"
            id="tab2"
            checked={state.tab == "tab2"}
            onClick={() => setState({ tab: "tab2" })}
          />
          <label for="tab2">Decline</label>
        </div>

        {state.tab == "tab1" && (
          <div className="pending_user_content mt-4">
            <Pendingtable />
          </div>
        )}

      

        {state.tab == "tab2" && (
          <div className="pending_user_content mt-4">
            {/* <Declinetable/> */}

            <div className="no_data text-center">
              <img src={Nodatagif} alt="" className="img-fluid" />
              <p>No Data Found!</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Usertabbing;
