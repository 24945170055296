import React from "react";
import {
  Table,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { Container } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";



// IMAGES
import User from "../../../../assets/images/placeholder.png"

const Filltertable = () => {
  return (
    <section className="Diagnosis_table com_table ">
      <Container>
        <div className="table_wrap" >
          <Table responsive>
            <thead className="sticky-top">
              <tr>
                
                <th>Picture</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>User Type</th>  
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6,7,8].map((items, idx) => {
                return (
                  <tr key={idx}>
                  
                    <td>
                      <div className="table_img">
                          <img src={User} alt="" className="img-fluid"/>
                      </div>
                    </td>
                    <td> Blood Pressure</td>
                    <td>+31854698454</td>
                    <td>abc@gmail.com</td>
                    <td>Established Users</td>

                    
                  </tr>
                );
              })}
            </tbody>
          </Table>

          
        </div>
        <div className="pagination_list d-flex align-items-center gap-3 justify-content-end py-3">
            <p>1-2 of items</p>
            <Pagination aria-label="Page navigation example">
              <PaginationItem>
                <PaginationLink previous href="#" />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#" className="active">
                  1
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">2</PaginationLink>
              </PaginationItem>

              <PaginationItem>
                <PaginationLink next href="#" />
              </PaginationItem>
            </Pagination>
          </div>
      </Container>
    </section>
  );
};

export default Filltertable;

const deleteicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
  >
    <path
      d="M1.17264 4.20649H2.30137V15.7647C2.30286 16.2911 2.51263 16.7955 2.88486 17.1677C3.25709 17.5399 3.76152 17.7497 4.28793 17.7512H12.7534C13.2759 17.7438 13.7745 17.5314 14.1419 17.1598C14.5093 16.7882 14.7159 16.2872 14.7174 15.7647V4.20649H15.8461C15.9958 4.20649 16.1393 4.14703 16.2452 4.04119C16.351 3.93536 16.4104 3.79181 16.4104 3.64213C16.4104 3.49245 16.351 3.3489 16.2452 3.24306C16.1393 3.13723 15.9958 3.07777 15.8461 3.07777H1.17264C1.02296 3.07777 0.879413 3.13723 0.773575 3.24306C0.667736 3.3489 0.608276 3.49245 0.608276 3.64213C0.608276 3.79181 0.667736 3.93536 0.773575 4.04119C0.879413 4.14703 1.02296 4.20649 1.17264 4.20649ZM13.5886 4.20649V15.7647C13.5886 15.9922 13.4983 16.2104 13.3374 16.3712C13.1765 16.5321 12.9583 16.6225 12.7308 16.6225H4.26535C4.04177 16.6166 3.82934 16.5236 3.67331 16.3634C3.51729 16.2031 3.43002 15.9883 3.43009 15.7647V4.20649H13.5886ZM6.25191 1.94904H10.7668C10.9165 1.94904 11.06 1.88958 11.1659 1.78374C11.2717 1.6779 11.3312 1.53435 11.3312 1.38468C11.3312 1.235 11.2717 1.09145 11.1659 0.985611C11.06 0.879772 10.9165 0.820313 10.7668 0.820312H6.25191C6.10223 0.820313 5.95868 0.879772 5.85284 0.985611C5.74701 1.09145 5.68755 1.235 5.68755 1.38468C5.68755 1.53435 5.74701 1.6779 5.85284 1.78374C5.95868 1.88958 6.10223 1.94904 6.25191 1.94904Z"
      fill="black"
    />
    <path
      d="M6.63571 13.6472C6.78539 13.6472 6.92894 13.5877 7.03478 13.4819C7.14062 13.376 7.20008 13.2325 7.20008 13.0828V6.31046C7.20008 6.16078 7.14062 6.01723 7.03478 5.91139C6.92894 5.80555 6.78539 5.74609 6.63571 5.74609C6.48603 5.74609 6.34249 5.80555 6.23665 5.91139C6.13081 6.01723 6.07135 6.16078 6.07135 6.31046V13.0828C6.07135 13.2325 6.13081 13.376 6.23665 13.4819C6.34249 13.5877 6.48603 13.6472 6.63571 13.6472ZM10.3831 13.6472C10.5328 13.6472 10.6763 13.5877 10.7822 13.4819C10.888 13.376 10.9474 13.2325 10.9474 13.0828V6.31046C10.9474 6.16078 10.888 6.01723 10.7822 5.91139C10.6763 5.80555 10.5328 5.74609 10.3831 5.74609C10.2334 5.74609 10.0899 5.80555 9.98402 5.91139C9.87818 6.01723 9.81872 6.16078 9.81872 6.31046V13.0828C9.81872 13.2325 9.87818 13.376 9.98402 13.4819C10.0899 13.5877 10.2334 13.6472 10.3831 13.6472Z"
      fill="black"
    />
  </svg>
);

const editicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
  >
    <path
      d="M13.9374 18.3329H3.02641C1.88488 18.3329 0.957031 17.4051 0.957031 16.2636V5.35258C0.957031 4.21105 1.88488 3.2832 3.02641 3.2832H9.42248C9.73401 3.2832 9.98684 3.53604 9.98684 3.84757C9.98684 4.15909 9.73401 4.41193 9.42248 4.41193H3.02641C2.5079 4.41193 2.08576 4.83411 2.08576 5.35258V16.2636C2.08576 16.7821 2.50794 17.2042 3.02641 17.2042H13.9374C14.4559 17.2042 14.878 16.782 14.878 16.2636V9.86749C14.878 9.55596 15.1309 9.30313 15.4424 9.30313C15.7539 9.30313 16.0068 9.55525 16.0068 9.86749V16.2636C16.0068 17.4051 15.0789 18.3329 13.9374 18.3329Z"
      fill="black"
    />
    <path
      d="M7.55785 12.0217C7.4096 12.0217 7.26506 11.963 7.15896 11.8562C7.02503 11.723 6.96785 11.531 7.00478 11.3467L7.53676 8.68592C7.5585 8.5767 7.61216 8.47638 7.69094 8.39767L15.4845 0.605015C16.2911 -0.201672 17.6036 -0.201672 18.4109 0.605015C18.8014 0.995484 19.0167 1.5148 19.0167 2.06788C19.0167 2.62096 18.8014 3.1401 18.4101 3.53075L10.6175 11.3242C10.5389 11.4031 10.4385 11.4568 10.3292 11.4784L7.66918 12.0104C7.63224 12.018 7.59464 12.0217 7.55785 12.0217ZM8.60979 9.07501L8.27788 10.7379L9.94014 10.4053L17.6124 2.73309C17.7901 2.55468 17.8879 2.3192 17.8879 2.06788C17.8879 1.81656 17.7901 1.58094 17.6125 1.40264C17.2468 1.03615 16.6501 1.03615 16.2822 1.40264L8.60979 9.07501Z"
      fill="black"
    />
    <path
      d="M16.9473 4.89965C16.8029 4.89965 16.6584 4.84466 16.5485 4.73401L14.4205 2.60527C14.2 2.38482 14.2 2.0274 14.4205 1.80694C14.6409 1.58649 14.9983 1.58649 15.2189 1.80694L17.3469 3.93569C17.5673 4.15614 17.5673 4.51356 17.3469 4.73401C17.2409 4.83998 17.0972 4.89955 16.9473 4.89965Z"
      fill="black"
    />
  </svg>
);
