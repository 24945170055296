import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Substable from "./Substables/substable";

import "rsuite/dist/rsuite.min.css";
import { DatePicker, Stack } from "rsuite";

// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import Plussvg from "../../../assets/svg/plussvg.svg";

const Subadmin = () => {
  const navigate = useNavigate();
  return (
    <Fade>
      <section className="subadmin_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <div className="resource_headwrap d-flex align-items-center justify-content-between">
              <h3 className="fillter_head">Manage Sub Admin</h3>
            </div>

            <div className="fillter_form_wrap d-flex align-items-center gap-3 mt-4">
              <div className="fillter_area d-flex">
                <div className="fillter_by d-flex align-items-center gap-3">
                  <h5>Filter by :</h5>
                  <div className="search_form d-flex align-items-center gap-3">
                    <FormGroup className="position-relative">
                      <Input
                        type="search"
                        name="email"
                        id="headersearch"
                        placeholder="Name"
                      />
                      <div className="search_icon">
                        <img src={Searchicon} alt="" className="img-fluid" />
                      </div>
                    </FormGroup>

                  

                    <FormGroup className="position-relative">
                    <Input
                      type="search"
                      name="email"
                      id="headersearch"
                      placeholder="Email"
                    />
                    <div className="search_icon">
                      <img src={Searchicon} alt="" className="img-fluid" />
                    </div>
                  </FormGroup>
                  </div>
                  <h5>Sort by :</h5>

                  <div className="dropdown_col">
                    <UncontrolledDropdown>
                      <DropdownToggle caret>A-Z</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>A-Z</DropdownItem>
                        <DropdownItem>Z-A</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>

              <div className="export_btn d-flex align-items-center gap-3">
                <Button
                  className="plussvg"
                  onClick={() => navigate("/add-subadmin")}
                >
                  <img src={Plussvg} alt="" className="img-fluid" />
                </Button>
                <Button className="export_csv">Export CSV format</Button>
              </div>
            </div>
          </div>
        </Form>

        <div className="manageuser_tabbingset mt-5">
          <Substable />
        </div>
      </section>
    </Fade>
  );
};

export default Subadmin;
