import React from "react";
import Categories from "Pages/Profile/Categories";


export default function Dashmaincategorytheme() {
  return (
    <>
       <Categories/>
    </>
  );
}
