import React from "react";
import Addnotification from "Pages/Profile/Notification/Addnotification";


export default function Dashaddnotifytheme() {
  return (
    <>
       <Addnotification/>
    </>
  );
}
