import React from "react";
import Pendingevents from "Pages/Profile/Pendinguser/Pendingevents";


export default function Dashpendingeventstheme() {
  return (
    <>
       <Pendingevents/>
    </>
  );
}
