import React, { useState } from "react";
import Fade from "react-reveal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Categorytab from "./categorytab";
import Addacategorymod from "Component/Modals/addacategorymod";

// images
import Searchicon from "../../../assets/svg/search_icon.svg";
import Plussvg from "../../../assets/svg/plussvg.svg";


const Categories = () => {
  const [categorymod, setCategorymod] = useState(false);
  return (
    <Fade>
      <Addacategorymod show={categorymod} onhide={()=> setCategorymod(false)}/>
      <section className="dashboard_first comon_dashpad">
        <Form>
          <div className="comom_fillter_set text-start">
            <h3 className="fillter_head">Manage Categories</h3>

            <div className="categories-header d-flex align-items-center justify-content-between mt-4">
              <h5>Resource Library Categories & SubCategories</h5>

              <div className="export_btn d-flex align-items-center gap-3">
                 <Button className="plussvg" onClick={()=> setCategorymod(true)}>
                    <img src={Plussvg} alt="" className="img-fluid"/>
                 </Button>
                <Button className="export_csv">Export CSV</Button>
              </div>
            </div>


            <div className="category_tab_set mt-5">
                <Categorytab/>
            </div>


          </div>
        </Form>
      </section>
    </Fade>
  );
};

export default Categories;



