import React from "react";
import Pendinguser from "Pages/Profile/Pendinguser/Users";


export default function Dashpendingusertheme() {
  return (
    <>
       <Pendinguser/>
    </>
  );
}
