import React from "react";
import Contestchallenges from "Pages/Profile/Pendinguser/Contestchallenges";


export default function Dashcotestchalltheme() {
  return (
    <>
       <Contestchallenges/>
    </>
  );
}
