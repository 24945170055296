import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const SortByFilter = ({ setBody, orderByKey }) => {
  const handleOrderChange = (order) => {
    setBody((prevBody) => ({
      ...prevBody,
      orderBy: orderByKey || "createdAt",
      order,
    }));
  };
  return (
    <div className="dropdown_col">
      <UncontrolledDropdown>
        <DropdownToggle caret>A-Z</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleOrderChange(1)}>A-Z</DropdownItem>
          <DropdownItem onClick={() => handleOrderChange(-1)}>Z-A</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default SortByFilter;
